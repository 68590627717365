import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import './assets/font/iconfont.css'
import './assets/less/common.less'
import './assets/less/main.less'
// @ts-ignore
import Api from "./api";

// import VConsole from 'vconsole';
// 本地开发调试注入vConsole
// if (process.env == 'development') { // 如果为开发模式, 则注入 vConsole, 预防正式会不小心忘记删除
  // new VConsole();
// }

const app = createApp(App)
app.config.globalProperties.$request = Api; // 全局挂载
app.use(store).use(router).mount('#app')
